.header {
  &-container {
    background-image: url("../../img/bg-6p-01a.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    gap: 20px;
  }

  &-text {
    &-welcome {
      color: #eee;
      font-weight: 600;
      font-size: 16px;

      @media screen and (max-width: 500px) {
        font-size: 14px;
      }
    }

    &-title {
      color: #eee;
      font-weight: 600;
      font-size: 26px;

      @media screen and (max-width: 600px) {
        font-size: 22px;
      }
    }
  }
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #0008;
}

.modal {
  border-radius: 10px;
  position: absolute;
  width: 100%;
  max-width: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateX(-5px);
  background-color: #fff;
  margin: 0 10px;
  padding: 15px;

  &-title {
    font-size: 20px;
    font-weight: 600;
  }

  &-item {
    cursor: pointer;
    padding: 5px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: 0.2s;
    font-size: 16px;

    &:hover {
      background-color: #0003;
    }
  }
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-bottom {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.table-component-container {
  height: 47px;
  width: 100%;
}

.total {
  padding: 10px;
  text-align: right;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  padding: 0 0 5px;

  @media screen and (max-width: 500px) {
    font-size: 16px;
  }
}

.generate-bill-button {
  cursor: pointer;
  width: 20%;
  min-width: 200px;
  margin: 0 10px;
  background-color: #1d1d1d;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #0000;
  transition: 0.4s;

  &-text {
    color: #eee;
    font-weight: 600;
  }

  &:hover {
    border-color: #1d1d1d;
    background-color: #0000;
  }

  &:hover > &-text {
    color: #1d1d1d;
  }
}

.main {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
}

.footer {
  width: 100%;
  margin-top: auto;
  bottom: 0;
  margin-top: auto;
  background-image: url("../../img/bg-6p-01a.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10px 15px;

  &-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-phrase {
    color: #eee;
    font-size: 18px;
    font-weight: 500;

    @media screen and (max-width: 500px) {
      font-size: 14px;
    }
  }
}
