.page {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url("../../img/bg-6p-01a.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.header {
  &-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: 0 20px;
}

.main {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 15px;
}

.form {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5%;

  &-title {
    &-container {
      margin: 0 0 20px;
      width: 100%;
    }

    text-align: center;
    color: #000;
    font-size: 28px;
    font-weight: 800;
    margin: 0;
  }

  &-container {
    display: flex;
    flex-direction: column;
    background-color: #eee;
    width: 100%;
    height: 100%;
    max-height: 400px;
    max-width: 450px;
    padding: 30px;
    border-radius: 10px;
  }
}

.input {
  flex: 1;
  color: #000a;
  background-color: #0000;
  border: none;
  outline: #0000;
  padding-left: 5px;
  height: 50px;
  font-size: 18px;

  &-container {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
    border: #555 1px solid;
    padding: 2px;
    border-radius: 5px;
  }
}

.button {
  &-container {
    margin-top: 10%;
    width: 100%;
    cursor: pointer;
    border: #0000;
    height: 50px;
    border-radius: 5px;
    padding: 5px 0;
    background-color: #1d1d1d;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  &-label {
    color: #eee;
    font-size: 18px;
  }
}

.sac-text-button {
  background-color: #0000;
  border: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #333;

  &:hover {
    filter: brightness(2);
  }
}
