.call-button {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: 0.6s background-color;

  &:hover {
    background-color: #eee8;
  }

  &-text {
    color: #eee;
    font-size: 16px;
    font-weight: 500;

    @media screen and (max-width: 500px) {
      font-size: 12px;
    }
  }

  background-color: #0000;
  padding: 15px 20px;
  border: 1px solid #eee;
  border-radius: 3px;

  @media screen and (max-width: 500px) {
    padding: 10px;
  }
}
